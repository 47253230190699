import axios from 'axios';
import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5';
import { toast } from 'react-toastify';

const Microdramas = () => {
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        email: '',
        phone: '',
        hashtag: '#episode',
        targetAudience: '',
        description: '',
        howYourContentStandsOut: ''
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://krizaar.com/api/shuffle/save-form-microdramas', {
                firstName: formData.firstName,
                lastName: formData.lastName,
                address: formData.address,
                email: formData.email,
                phone: formData.phone,
                hashtag: formData.hashtag,
                targetAudience: formData.audience,
                description: formData.description,
                howYourContentStandsOut: formData.howYourContentStandsOut
            })
            if (response) {
                toast.success("Form Submitted successfully!")
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to submit form. Please try again later.")
        }
        setFormData({
            firstName: '',
            lastName: '',
            address: '',
            email: '',
            phone: '',
            targetAudience: '',
            description: '',
            howYourContentStandsOut: ''
        });
        setShowModal(false);
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <div className='microdramas text-white'>
                <div className='dramas-hero pt-5'>
                    <div className='dramas-hero-text'>
                        <h3>Redefining Short-Form Storytelling</h3>
                        <h1>Welcome to Shuffle Microdramas </h1>
                        <h5>Are you a visionary content creator with a knack for storytelling? </h5>
                        <p>Shuffle is on the hunt for <span>Microdrama</span> series, a new genre of content that packs compelling narratives into <span>1 minute episodes.</span></p>
                        <p>We’re inviting creators to pitch their microdrama ideas for the chance to have their content purchased and featured as exclusive Shuffle originals!</p>
                    </div>
                </div>
                <div className='section my-5 py-5 text-center container'>
                    <div className='text-white'>
                        <div className='section-div section-1'>
                            <div className='section-text  pe-5'>
                                <h1 className='section-header'>What Are Microdramas?</h1>
                                <p style={{ fontSize: '1.2rem' }}>Microdramas are short-form episodic stories designed for today’s fast-paced digital audience.</p>
                                <p style={{ fontSize: '1.2rem' }}>Each season consists of:</p>
                                <ul style={{ fontSize: '1.2rem' }}>
                                    <li><b>60 Episodes:</b> Each just under 10 minutes long, delivering maximum impact in minimal time.
                                    </li>
                                    <li><b>Exclusive Content:</b> Either purchased or licensed by Shuffle to showcase on our platform.</li>
                                </ul>
                            </div>
                            <div className='section-image'>
                                <img className='img-fluid' src='/microdramas/live_img.png' alt='Dancing' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='section my-5 py-5 text-center container'>
                    <div className='text-white'>
                        <div className='section-div d-flex flex-row-reverse section-2'>
                            <div className='section-text ps-5'>
                                <h1 className='section-header'>What We’re Looking For</h1>
                                <ul style={{ fontSize: '1.2rem' }}>
                                    <li><b>Engaging Narratives: </b> Stories that captivate in just under 10 minutes.</li>
                                    <li><b>Innovative Concepts: </b> Fresh ideas across genres like drama, comedy, thriller, sci-fi, or anything new.</li>
                                    <li><b>High-Quality Production: </b> Well-executed videos that elevate the short-form viewing experience.</li>
                                </ul>
                            </div>
                            <div className='section-image pe-5'>
                                <img className='img-fluid' src='/microdramas/img1.jpg' alt='Dancing' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='section my-5 text-center container'>
                    <h1 className='section-title'>How to Submit Your Proposal</h1>
                    <div className='boxes mt-5 row d-flex justify-content-center gap-3'>
                        <p className='box col-lg-3 col-md-5 col-9'><b>1. Create Your Episodes </b> <br /><br />Produce a sample episode showcasing your concept and creative vision.<br /><br />By submitting your work for consideration to Shuffle you are agreeing to our limited distribution and licensing policy.</p>
                        <p className='box col-lg-3 col-md-5 col-9'><b>2. Upload to Shuffle </b> <br /><br />
                            Post your episodes on Shuffle Hub with the hashtag <b>#shufflemicrodramas,</b> along with your episode name and number (e.g., <b>#MysteryNight01</b>).
                        </p>
                        <p className='box col-lg-3 col-md-5 col-9'><b>3. Share Your Vision </b> <br /><br />Submit the form below<br />
                            <button className='btn-primary2 mt-4' onClick={handleShowModal}>Form</button></p>
                    </div>
                </div>

                {showModal && (
                    <div>
                        <div
                            className="modal-backdrop fade show"
                            style={{
                                backgroundColor: 'rgb(255, 255, 255, 0.2)',
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 1040,
                            }}
                        ></div>

                        <div className='modal fade show' style={{ display: 'block', zIndex: 1050 }} tabIndex='-1'>
                            <div className='modal-dialog modal modal-dialog-centered modal-lg'>
                                <div className='modal-content px-5 py-2 bg-black'>
                                    <div className='modal-header'>
                                        <h5 className='modal-title'>Share Your Vision</h5>
                                        <button type='button' className='btn-close' style={{ fontSize: '1.5rem', color: 'white' }} onClick={handleCloseModal}><IoClose /></button>
                                    </div>
                                    <div className='modal-body'>
                                        <form onSubmit={handleSubmit}>
                                            <div className='row'>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='firstName' className='form-label'>First Name</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='firstName'
                                                        value={formData.firstName}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='lastName' className='form-label'>Last Name</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='lastName'
                                                        value={formData.lastName}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='phone' className='form-label'>Phone Number</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='phone'
                                                        value={formData.phone}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='email' className='form-label'>Email</label>
                                                    <input
                                                        type='email'
                                                        className='form-control'
                                                        id='email'
                                                        value={formData.email}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor='address' className='form-label'>Address</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='address'
                                                    value={formData.address}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className='row'>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='phone' className='form-label'>Hashtag</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='hashtag'
                                                        value={formData.hashtag}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor='phone' className='form-label text-white'>Target Audience</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='targetAudience'
                                                    value={formData.targetAudience}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor='phone' className='form-label text-white'>Description of series concept</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='description'
                                                    value={formData.description}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor='phone' className='form-label text-white'>How your content stands out?</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='howYourContentStandsOut'
                                                    value={formData.howYourContentStandsOut}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className='my-3 gap-4 d-flex justify-content-center'>
                                                <button className='btn btn-danger' onClick={handleCloseModal}>
                                                    Close
                                                </button>
                                                <button type='submit' className='btn-primary'>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                <div className='section my-5 py-5 text-center container'>
                    <div className='text-white'>
                        <div className='section-div section-3'>
                            <div className='section-text pe-5'>
                                <h1 className='section-header'>What We’re Looking For</h1>
                                <ul style={{ fontSize: '1.2rem' }}>
                                    <li><b>Monetize Your Creativity: </b>Get paid for your work as Shuffle purchases or licenses your content library for exclusive use.</li>
                                    <li><b>Reach a Global Audience: </b> Share your stories with Shuffle’s growing user base.</li>
                                    <li><b>Be a Pioneer: </b> Help shape the future of short-form content and storytelling.</li>
                                </ul>
                            </div>
                            <div className='section-image ps -5'>
                                <img className='img-fluid' src='/microdramas/img3.jpg' alt='Dancing' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='section my-5 py-5 text-center container'>
                    <div className='text-white'>
                        <div className='section-div d-flex flex-row-reverse section-4'>
                            <div className='section-text ps-5'>
                                <h1 className='section-header'>Timeline</h1>
                                <ul style={{ fontSize: '1.2rem' }}>
                                    <li><b>Submission Deadline: </b> Don’t wait! Start submitting your episodes now.</li>
                                    <li><b>Selection Process: </b> Shuffle will review submissions and notify creators whose content has been selected.</li>
                                </ul>
                            </div>
                            <div className='section-image pe-5'>
                                <img className='img-fluid' src='/microdramas/img2.jpg' alt='Dancing' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='section my-5 py-5 text-center container'>
                    <h1 className='section-title'>What's Next?</h1>
                    <p style={{ fontSize: '1.5rem' }}>Once your proposal is selected, Shuffle will collaborate with you to bring your <b>60-episode season</b> to life as part of our exclusive content collection.</p>
                </div>

                <div className='section microdramas-banner2 my-5 py-5 text-center container'>
                    <h1 style={{ fontSize: '5rem', fontWeight: '800' }}>Start Creating Today!</h1>
                    <p style={{ fontSize: '2rem', fontWeight: '600' }}>Got a story to tell in 60 seconds? We’re ready to see it!</p>
                    <p style={{ fontSize: '2rem', fontWeight: '600' }}>Upload Your Episodes Now on Shuffle and join the future of Microdrama storytelling.</p>
                    <p style={{ fontSize: '2rem', fontWeight: '600' }}>Let’s create something unforgettable, one minute at a time.</p>
                </div>
            </div>
        </>
    )
}

export default Microdramas
