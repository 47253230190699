import React, { useEffect } from "react";

const TermsOfService = () => {
    return (
        <>
            <section className="text-white">
                <div className="container">
                    <div className='terms-of-service'>
                        <h1 className="my-3">Terms of Service for Shuffle </h1>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div style={{ textAlign: 'justify' }}>
                                <p>Welcome to Shuffle, a platform provided by Shuffle Inc. in the United States. This document outlines the terms of service (“Terms”) that govern the relationship between you and Shuffle. These Terms form a binding agreement for how you may access and use Shuffle’s websites, apps, and services (collectively referred to as “Services”). Some features may have age restrictions and are not available to all users. Shuffle’s Services are intended for private, non-commercial use. The terms “you” and “your” refer to the user of the Services.</p>
                                <p>If you're under 18, you can only use Shuffle with parental or guardian consent. Ensure they have reviewed these Terms with you.</p>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>2. Acceptance of the Terms</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>By using Shuffle’s Services, you confirm that you accept and agree to these Terms. You also agree to follow the Privacy Policy and Community Guidelines, available on the Shuffle platform or app store. If you use Shuffle on behalf of a business, you confirm you have the authority to bind that business to these Terms. By continuing to use the Services, you agree to these Terms, and you should save a copy for future reference.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>3. Changes to the Terms</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle may update these Terms over time, for example, due to updates in functionality or regulatory changes. While we will make reasonable efforts to notify users of material changes, you are responsible for regularly checking the Terms. Your continued use of the Services after updates confirms your acceptance of the new Terms.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>4. Your Account</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>To use some Shuffle features, you must create an account with accurate and up-to-date information. Keep your account password confidential and notify us immediately if you suspect unauthorized access. You are responsible for any activity that occurs under your account. If you no longer want to use Shuffle, you can request account deletion, but you won’t be able to recover any content or information once your account is deleted.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>5. Your Access to and Use of Services</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>When using Shuffle, you must comply with the law and the following rules:
                                            <ul>
                                                <li>You cannot modify or distribute the Services or reverse engineer them.</li>
                                                <li>You cannot use the Services for commercial purposes without Shuffle's written consent.</li>
                                                <li>You cannot impersonate others or share unauthorized or harmful content.</li>
                                                <li>You cannot upload viruses or malicious software.</li>
                                                <li>You must comply with Shuffle’s Community Guidelines at all times.</li>
                                            </ul>
                                            <p>Shuffle may remove content or disable access if these rules are violated. Additionally, Shuffle uses automated systems to analyze content, ensuring personalized features and detecting harmful materials.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>6. Intellectual Property</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle owns the content and intellectual property associated with the platform, referred to as "Shuffle Content." You cannot use, download, or distribute this content without permission. By using Shuffle, you agree that Shuffle may benefit from your use of the Services (e.g., through advertising) without owing you compensation. You are granted a limited, personal, non-commercial license to use the Services, which can be revoked at any time.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>7. User-Generated Content</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <p>You may upload content (e.g., videos, photos, audio) to Shuffle, referred to as “User Content.” By doing so, you grant Shuffle a worldwide, non-exclusive, royalty-free license to use, distribute, and modify your User Content. You retain ownership of your content but must ensure that you have all the necessary permissions to share it. Shuffle reserves the right to remove or edit your content if it violates the Terms or Community Guidelines.</p>
                                            <p>Shuffle may use your User Content for advertising or marketing purposes without compensating you. You also waive any rights to inspect or approve how your content is used. Shuffle is not responsible for content uploaded by other users, and you may report harmful content to Shuffle for review.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>8. Indemnity</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>You agree to indemnify and hold Shuffle and its affiliates harmless from any claims or legal action resulting from your violation of these Terms or misuse of the Services.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>9. Exclusion of Warranties</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle’s Services are provided "as is" without any warranties. Shuffle does not guarantee that the Services will meet your needs, be error-free, or function without interruption. Shuffle may modify or withdraw the Services at any time without notice.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>10. Limitation of Liability</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle’s liability to you is limited to the amount you’ve paid in the last 12 months. Shuffle is not responsible for indirect or consequential losses, including loss of profit or data. You use the Services at your own risk, and Shuffle is not liable for issues like failed transactions, lost data, or unauthorized access.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>11. Other Terms</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            <ul>
                                                <li>Shuffle contains open-source software, which is governed by separate licenses.</li>
                                                <li>The Terms replace any prior agreements you have with Shuffle.</li>
                                                <li>Links to third-party websites on Shuffle do not imply endorsement.</li>
                                                <li>Shuffle does not guarantee that its Services are free from viruses, so you should use virus protection.</li>
                                                <li>If any part of these Terms is found invalid, the rest will still apply.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>12. Dispute Resolution</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Before filing legal action, both parties agree to attempt resolving disputes informally. Legal disputes will be governed by the laws of California, and any actions must be filed within one year of the event that caused the dispute.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>13. App Stores</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Supplemental terms apply if you download Shuffle through third-party app stores (Apple, Google Play, Amazon, Microsoft). These platforms are not responsible for Shuffle’s content or services, and your use of the platform must comply with their respective terms of service.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>14. Contact Us</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>For any questions or concerns about these Terms, contact Shuffle through their official channels.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TermsOfService;
