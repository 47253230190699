import React from 'react'
import HeroSection from './HeroSection'
// import Footer from '../../../componenth/header/Footer'
import './commonotherlink.css'
import Content from './Content'
// import Header from './Header'

const CommonOtherLinkComponent = ({contentArray, heroSectionItems, subtitle, middleBannerContent, title, bottomBigContent, loginPage}) => {
  return (
    <>
      <div className='krizaar-pay mt-5 pt-5'>
        {/* <Header/> */}
        <HeroSection heroSectionItems={heroSectionItems} loginPage={loginPage} />
        <Content contentArray={contentArray} title={title} subtitle={subtitle} middleBannerContent={middleBannerContent} bottomBigContent={bottomBigContent} />
        {/* <Footer/> */}
      </div>
    </>
  )
}

export default CommonOtherLinkComponent
