import React from 'react'
import { useNavigate } from 'react-router-dom';

const DeleteYourAccount = () => {
  const navigate = useNavigate()


  const deleteAccount = () => {
    // try{
    //   const res = axios.post(`${REACT_BASE_PATH}/auth/user/registration`,{
    //     user: {
    //       id: userId
    //     }
    //   })
    //   if(res){
    //     Cookies.remove('krizaar-user');
    //     localStorage.removeItem('userId');
    //     navigate('/signin')
    //     toast.success('Account deleted successfully')
    //     return;
    //   }
    // }catch(e){
    //   console.error(e)
    //   toast.error('Failed to delete account')
    //   return;
    // }
  }

  return (
    <>
      <div className="container">
        <div className=' text-center'>
          <div className='terms-of-service'>
            <h1 className="my-3">Delete Your Account</h1>
            <div className='checkout__step-header flex-column align-items-center justify-content-center gap-4' style={{ cursor: 'auto' }} >
              <h5 className=' text-white'>Are you sure want to delete your account?</h5>
              <div className=' d-flex gap-5'>
                <button className='btn-primary' onClick={deleteAccount}>Confirm</button>
                <button className='btn-primary' onClick={() => {
                  navigate(-1)
                }}>Go Back</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DeleteYourAccount
