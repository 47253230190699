import React from 'react'
import './newstyles.css'
import image from './pic4.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const ShuffleStudios = () => {
    return (
        <>
            <div className='affluence-section'>
                <div className='affluence-section-hero'>
                    <div className='affluence-section-hero1'>
                        <h1>Welcome to <span>Shuffle Studios</span></h1>
                        <p>Empowering Content Creators with Fairness and Transparency</p>
                    </div>
                </div>
                <div className='container'>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Problem and Solution</h1>
                        </div>
                        <div className='affluence-problem-section'>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-problem-section-text'>
                                <h4>The Problem</h4>
                                <h2>Protecting Creativity in a Distrustful Landscape</h2>
                                <p>Are you a content creator struggling to safeguard your work? From copyright infringement to opaque earnings reports, musicians and video creators face a lack of control, transparency, and trust in traditional distribution models. The result? Reduced income and diminished confidence in the system.</p>
                            </div>
                        </div>
                        <div className='affluence-problem-section mt-5'>
                            <div className='affluence-problem-section-text'>
                                <h4>The Solution</h4>
                                <h2>Shuffle Studios - Where Your Work Earns the Recognition It Deserves</h2>
                                <p>Shuffle Studios is your gateway to a secure, transparent, and fair platform. Designed for musicians and video creators, it leverages blockchain technology to protect copyright claims, ensure fair monetization, and provide detailed analytics—all within the Shuffle Hub ecosystem.</p>
                            </div>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Why Shuffle Studios ?</h1>
                        </div>
                        <div className='why-affluences-boxes'>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Copyright Protection with Blockchain</h2>
                                    <p>Every piece of content you upload is secured and timestamped on the blockchain, creating an immutable record that proves your ownership and safeguards against copyright infringement.</p>
                                </div>
                            </div>
                            <div className='box2'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Transparent Monetization</h2>
                                    <p>No more guessing games. Track exactly how your content is performing with real-time analytics and clear metrics. Shuffle Studios ensures that your engagement translates directly into income.</p>
                                </div>
                            </div>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Limited Distribution, Maximum Impact</h2>
                                    <p>Release your work on a controlled basis within Shuffle Hub, ensuring exclusivity and focused audience engagement.</p>
                                </div>
                            </div>
                            <div className='box2'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Income from Engagement</h2>
                                    <p>Earn directly from the interactions your content generates. Whether it’s views, listens, or shares, your creativity drives your revenue.</p>
                                </div>
                            </div>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Trusted Ecosystem</h2>
                                    <p>Shuffle Studios integrates seamlessly with Shuffle Hub’s vibrant community of users, live streaming features, and e-commerce marketplace to maximize your reach and impact.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>How It Works ?</h1>
                        </div>
                        <div className="affluence-how-it-works2">
                            <div className='affluence-how-it-works-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-how-it-works-text'>
                                <div className='affluence-box'>
                                    <h3>1.	Sign Up & Upload</h3>
                                    <p>Register with Shuffle Studios and upload your copyrighted audio or video content.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>2.	Blockchain Verification</h3>
                                    <p>Your work is instantly secured and verified on the blockchain, protecting your ownership rights.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>3.	Set Distribution Parameters</h3>
                                    <p>Control how and where your content is distributed within Shuffle Hub for maximum engagement.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>4.	Monitor & Earn</h3>
                                    <p>Track your performance with transparent analytics and enjoy direct payouts for your content’s engagement.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Your Creativity, Fully Valued</h1>
                        </div>
                        <div className='join-community gap-5'>
                            <div className="join-community-box">
                                <h3>For Musicians</h3>
                                <p>Share your tracks with Shuffle Hub’s exclusive audience and earn income from every listen and interaction.</p>
                            </div>
                            <div className="join-community-box">
                                <h3>For Video Creators</h3>
                                <p>Release your videos to an engaged community while maintaining full control over your rights and revenue.</p>
                            </div>
                            <div className="join-community-box">
                                <h3>For All Creators</h3>
                                <p>Enjoy a platform that prioritizes fairness, transparency, and recognition for your work.</p>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5 d-flex flex-column'>
                            <h1>The Blockchain Advantage</h1>
                        </div>
                        <div className="affluence-how-it-works">
                            <Swiper
                                // slidesPerView={3}
                                spaceBetween={0}
                                centeredSlides={true}
                                loop={true}
                                speed={1500}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                    }
                                }}
                                autoplay={true}
                                modules={[Autoplay, Navigation]}
                            >
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Immutable Copyright Records</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Every upload is time-stamped and recorded, ensuring undeniable proof of ownership.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Real-Time Analytics</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Transparent and detailed insights into how your content performs.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Fair Revenue Distribution</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Blockchain ensures accurate and trustless payouts for your engagement metrics.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Immutable Copyright Records</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Every upload is time-stamped and recorded, ensuring undeniable proof of ownership.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Real-Time Analytics</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Transparent and detailed insights into how your content performs.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Fair Revenue Distribution</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Blockchain ensures accurate and trustless payouts for your engagement metrics.</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Join the Revolution in Content Creation</h1>
                        </div>
                        <div className='affluence-problem-section'>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-problem-section-text d-flex justify-content-around'>
                                <div className='affluence-box'>
                                    <p>Shuffle Studios isn’t just a platform; it’s a movement to empower creators like you. With blockchain-powered copyright protection and transparent monetization, you can focus on what you do best—creating amazing content—while we handle the rest.</p>
                                </div>
                                <div>
                                    <h2 className='mb-3'>Get Started Now</h2>
                                    <div className='affluence-box'>
                                        <p>Join Shuffle Studios and unlock the full potential of your creativity today.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShuffleStudios
