import React from 'react'
import './newstyles.css'
import image from './pic4.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const SellerClub = () => {
    return (
        <>
            <div className='affluence-section'>
                <div className='affluence-section-hero'>
                    <div className='affluence-section-hero1'>
                        <h1>Welcome to <span>The Seller’s Club</span></h1>
                        <p>Empowering Merchants, Redefining E-Commerce</p>
                    </div>
                </div>
                <div className='container'>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Problem and Solution</h1>
                        </div>
                        <div className='affluence-problem-section'>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-problem-section-text'>
                                <h4>The Problem</h4>
                                <h2>Unfair Practices in Traditional Marketplaces</h2>
                                <p>Are you tired of losing your competitive edge to marketplace operators who copy your best-selling products? Do counterfeit risks and lack of transparency undermine your business’s growth? The conventional e-commerce landscape often leaves merchants vulnerable to conflict of interest and mistrust.</p>
                            </div>
                        </div>
                        <div className='affluence-problem-section mt-5'>
                            <div className='affluence-problem-section-text'>
                                <h4>The Solution</h4>
                                <h2>The Seller’s Club - Built on Blockchain for Fairness and Trust</h2>
                                <p>The Seller’s Club, a part of Shuffle Hub’s innovative ecosystem, is your gateway to a transparent and equitable e-commerce platform. By leveraging blockchain technology, we protect merchants from unfair practices and counterfeit risks while fostering a trusted marketplace for all stakeholders.</p>
                            </div>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Why The Seller’s Club ?</h1>
                        </div>
                        <div className='why-affluences-boxes'>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Seamless Integration</h2>
                                    <p>Connect your Shopify store effortlessly and list products directly on Shuffle Shops, expanding your reach to a broader audience.</p>
                                </div>
                            </div>
                            <div className='box2'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Dropshipping Simplified</h2>
                                    <p>Source high-quality products through Krizaar, our trusted dropshipping service, and eliminate the hassle of inventory management.</p>
                                </div>
                            </div>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Blockchain-Powered Transparency</h2>
                                    <p>Every transaction and product listing is recorded immutably on the blockchain. This ensures accountability, eliminates counterfeit risks, and builds trust among merchants and customers.</p>
                                </div>
                            </div>
                            <div className='box2'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Fair Competition, Guaranteed</h2>
                                    <p>Our decentralized architecture prevents marketplace operators from exploiting your data or competing unfairly by replicating your products.</p>
                                </div>
                            </div>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Trusted Ecosystem</h2>
                                    <p>The Seller’s Club is part of the Shuffle Hub platform, integrating seamlessly with video sharing, live streaming, and e-commerce features to maximize your sales potential.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>How It Works ?</h1>
                        </div>
                        <div className="affluence-how-it-works2">
                            <div className='affluence-how-it-works-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-how-it-works-text'>
                                <div className='affluence-box'>
                                    <h3>1. Sign Up & Connect</h3>
                                    <p>Easily onboard your Shopify store or explore products from Krizaar’s dropshipping catalog.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>2. List & Sell</h3>
                                    <p>Showcase your products on Shuffle Shops, optimized for visibility and conversions..</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>3. Secure Transactions</h3>
                                    <p>Blockchain integration ensures every transaction is transparent and counterfeit-free.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>4. Grow & Succeed</h3>
                                    <p>Leverage powerful analytics and marketing tools to maximize your sales potential.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Your Success, Our Mission</h1>
                        </div>
                        <div className='join-community gap-5'>
                            <div className="join-community-box">
                                <h3>For Shopify Merchants</h3>
                                <p>Expand your business beyond your current reach by tapping into Shuffle Hub’s extensive customer base.</p>
                            </div>
                            <div className="join-community-box">
                                <h3>For Dropshippers</h3>
                                <p>Source from Krizaar’s trusted network of products and build a scalable business with minimal overhead.</p>
                            </div>
                            <div className="join-community-box">
                                <h3>For All Merchants</h3>
                                <p>Enjoy peace of mind with a platform that prioritizes your success without competing against you.</p>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5 d-flex flex-column'>
                            <h1>The Blockchain Advantage</h1>
                        </div>
                        <div className="affluence-how-it-works">
                            <Swiper
                                // slidesPerView={3}
                                spaceBetween={0}
                                centeredSlides={true}
                                loop={true}
                                speed={1500}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                    }
                                }}
                                autoplay={true}
                                modules={[Autoplay, Navigation]}
                            >
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Immutable Records</h5>
                                        <div className='line'></div>
                                        <p className="m-0">All transactions and listings are secured on the blockchain, ensuring full transparency and accountability.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Elimination of Counterfeit Risks</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Protect your brand and customers by ensuring the authenticity of every product.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Conflict-Free Marketplace</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Decentralization eliminates the potential for marketplace operators to exploit merchant data for their gain.</p>
                                    </div>
                                </SwiperSlide>
                                
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Immutable Records</h5>
                                        <div className='line'></div>
                                        <p className="m-0">All transactions and listings are secured on the blockchain, ensuring full transparency and accountability.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Elimination of Counterfeit Risks</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Protect your brand and customers by ensuring the authenticity of every product.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Conflict-Free Marketplace</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Decentralization eliminates the potential for marketplace operators to exploit merchant data for their gain.</p>
                                    </div>
                                </SwiperSlide>
                                
                            </Swiper>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Join the Revolution</h1>
                        </div>
                        <div className='affluence-problem-section'>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-problem-section-text d-flex justify-content-around'>
                                <div className='affluence-box'>

                                    <p>The Seller’s Club is transforming the way merchants and customers interact in the e-commerce space. By combining seamless integration, dropshipping, and blockchain technology, we empower you to thrive in a marketplace built on trust, transparency, and fairness.</p>
                                </div>
                                <div>
                                    <h2 className='mb-3'>Get Started Now</h2>
                                    <div className='affluence-box'>
                                        <p>Join The Seller’s Club and redefine your e-commerce journey today.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SellerClub
