import React from 'react';
import Card from './ExplorePage_Components/Card';
const Explore = () => {
  const portals = [
    {
      title: "Shop",
      description: "A decentralized marketplace",
      bgimage: "/images/fetch.png",
      image: "/images/shop.png",
      url: "https://krizaar.com/"
    },
    {
      title: "Seller",
      description: "Launch your store on Krizaar",
      bgimage: "/images/fetch.png",
      image: "/images/seller.png",
      url: "/sellersclub"
    },
    // {
    //   title: "Affiliate",
    //   description: "A decentralized affiliate marketing platform",
    //   bgimage: "/images/fetch.png",
    //   image: "/images/affiliate.png",
    //   url: "/affluence"
    // },
    {
      title: "Studios",
      description: "AI powered image, motion and audio recording studio for content creators",
      bgimage: "/images/fetch.png",
      image: "/images/studios.png",
      url: "/studios"
    },
    {
      title: "Advertise",
      description: "A decentralized advertising platform (beta)",
      bgimage: "/images/fetch.png",
      image: "/images/advertise.png",
      url: "/adjunction"
    },
    {
      title: "Compliance",
      description: "A decentralized identity verification system. Where you are in control of your data.",
      bgimage: "/images/fetch.png",
      image: "/images/compliance.png",
      url: "/compliance"
    },
    // {
    //   title: "Voting",
    //   description: "A decentralized voting mechanism that enables community governance of Shuffle (beta)",
    //   bgimage: "/images/fetch.png",
    //   image: "/images/voting.png",
    //   url: "/voting"
    // },
    // {
    //   title: "Moderation",
    //   description: "A decentralized moderation mechanism that enables community governance of Shuffle (beta)",
    //   bgimage: "/images/fetch.png",
    //   image: "/images/moderation.png",
    //   url: "/moderation"
    // },
    // {
    //   title: "Monetization",
    //   description: "Channel analytics and monetization platform for Shuffle (beta)",
    //   bgimage: "/images/fetch.png",
    //   image: "/images/monetization.png",
    //   url: "/monetization"
    // },
    // {
    //   title: "Streaming",
    //   description: "A decentralized music streaming service (beta)",
    //   bgimage: "/images/fetch.png",
    //   image: "/images/streaming.png",
    //   url: "/streaming"
    // },
    {
      title: "Kriza Dropship",
      description: "Launch your store without the hassle of logistics management (beta)",
      bgimage: "/images/fetch.png",
      image: "/images/dropship.png",
      url: "/krizadropship"
    },
    // {
    //   title: "Logistics",
    //   description: "Third Party Logistics service providers and Motor Carriers can move freight for us by signing up.",
    //   bgimage: "/images/fetch.png",
    //   image: "/images/logistics.png",
    //   url: "/logistics"
    // },
  ];
  
  return (
    <div className="explore-page p-0 mt-5 pt-5">
      <div className="explore-header">
        <h1>Explore and discover</h1>
      </div>
      <div className='container'>
        <div className="featured-container row">
          {portals.map((portal, index) => (
            <Card
              key={index}
              bgimage={portal.bgimage}
              image={portal.image}
              title={portal.title}
              members={portal.members}
              posts={portal.posts}
              description={portal.description}
              url={portal.url}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Explore;
