import React from 'react'
import './newstyles.css'
import image from './pic4.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const KrizaDropship = () => {
    return (
        <>
            <div className='affluence-section'>
                <div className='affluence-section-hero'>
                    <div className='affluence-section-hero1'>
                        <h1>Welcome to <span>Kriza Dropship</span></h1>
                        <p>Your Gateway to Effortless E-commerce Success</p>
                    </div>
                </div>
                <div className='container'>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>About Us</h1>
                        </div>
                        <div className='affluence-problem-section'>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-problem-section-text'>
                                <h2>Your Partner in Growth</h2>
                                <p>Kriza Dropship is the ultimate dropshipping service within the Seller’s Club platform by Shuffle Hub. Designed to empower e-commerce vendors and Shopify store owners, we make it easy to source, list, and sell the hottest products—all while ensuring seamless delivery to customers across the United States.</p>
                            </div>
                        </div>
                        <div className='affluence-problem-section mt-5'>
                            <div className='affluence-problem-section-text'>
                                <h2>Your Gateway to Success.</h2>
                                <p>Kriza Dropship is part of the Seller’s Club, a platform by Shuffle Hub that’s dedicated to empowering merchants through innovative e-commerce solutions. Together, we’re building a future where online business is simpler, faster, and more profitable for everyone.</p>
                            </div>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Why Choose Kriza Dropship ?</h1>
                        </div>
                        <div className='why-affluences-boxes'>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Global Product Sourcing Made Easy</h2>
                                    <p>Connect with a vast network of reliable suppliers worldwide. Gain access to trending and in-demand products that are ready for listing in your store.</p>
                                </div>
                            </div>
                            <div className='box2'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Seamless Integration</h2>
                                    <p>Kriza Dropship integrates effortlessly with your Shopify store, so you can start listing products and selling in no time.</p>
                                </div>
                            </div>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Hassle-Free Fulfillment</h2>
                                    <p>Forget the logistics headaches! With Krizaar’s trusted delivery network, we handle transportation and delivery so you can focus on growing your business.</p>
                                </div>
                            </div>
                            <div className='box2'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Stay Ahead of the Trends</h2>
                                    <p>Our platform provides insights and data to help you identify the hottest products in the market. Stay ahead of your competition by offering what customers want most.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>How It Works ?</h1>
                        </div>
                        <div className="affluence-how-it-works2">
                            <div className='affluence-how-it-works-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-how-it-works-text'>
                                <div className='affluence-box'>
                                    <h3>1.	Explore the Marketplace</h3>
                                    <p>Browse through our curated selection of global suppliers and products.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>2.	Add Products to Your Store</h3>
                                    <p>Choose the products you want to sell and sync them directly with your Shopify store.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>3.	Start Selling</h3>
                                    <p>Your customers shop, and we take care of the rest—from order processing to delivery.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>4.	Monitor and Grow</h3>
                                    <p>Use our intuitive dashboard to track sales, manage inventory, and scale your business.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Who Can Benefit?</h1>
                        </div>
                        <div className='join-community gap-5'>
                            <div className="join-community-box">
                                <h3>E-commerce Vendors</h3>
                                <p>Expand your inventory without the upfront costs or storage requirements.</p>
                            </div>
                            <div className="join-community-box">
                                <h3>Shopify Store Owners</h3>
                                <p>Seamlessly list and sell products with full integration into your existing store.</p>
                            </div>
                            <div className="join-community-box">
                                <h3>Entrepreneurs</h3>
                                <p>Start a business with minimal investment and maximum potential.</p>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5 d-flex flex-column'>
                            <h1>Powered by Trust and Transparency</h1>
                            <p>Kriza Dropship leverages blockchain technology to create a trusted ecosystem for suppliers, vendors, and customers. This means:</p>
                        </div>
                        <div className="affluence-how-it-works">
                            <Swiper
                                // slidesPerView={3}
                                spaceBetween={0}
                                centeredSlides={true}
                                loop={true}
                                speed={1500}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                    }
                                }}
                                autoplay={true}
                                modules={[Autoplay, Navigation]}
                            >
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Authenticity Guaranteed</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Each product is verified for quality and authenticity.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Fair Business Practices</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Transparent transactions eliminate risks of disputes and counterfeit products.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Reliable Data</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Access real-time analytics and insights to make informed decisions.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Authenticity Guaranteed</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Each product is verified for quality and authenticity.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Fair Business Practices</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Transparent transactions eliminate risks of disputes and counterfeit products.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>Reliable Data</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Access real-time analytics and insights to make informed decisions.</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Join Kriza Dropship Today!</h1>
                        </div>
                        <div className='affluence-problem-section'>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-problem-section-text d-flex justify-content-around'>
                                <div className='affluence-box'>
                                    <p>Take the guesswork out of dropshipping. Whether you’re scaling your e-commerce empire or starting fresh, Kriza Dropship has the tools, resources, and network you need to succeed.</p>
                                </div>
                                <div>
                                    <h2 className='mb-3'>Get Started in Minutes!</h2>
                                    <div className='affluence-box'>
                                        <p>Sign Up Now and transform your business with Kriza Dropship.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default KrizaDropship
