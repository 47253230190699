import axios from 'axios';
import React, { useState } from 'react'
import { IoClose } from 'react-icons/io5';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

const Dance = () => {
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        email: '',
        phone: '',
        hashtag: '#dance',
        profileHandle: '',
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://krizaar.com/api/shuffle/save-form-danceoff', {
                firstName: formData.firstName,
                lastName: formData.lastName,
                address: formData.address,
                email: formData.email,
                phone: formData.phone,
                hashtag: formData.hashtag,
                profileHandle: formData.profileHandle
            })
            if (response) {
                toast.success("Form Submitted successfully!")
            }
        } catch (error) {
            console.error(error);
            toast.error("Failed to submit form. Please try again later.")
        }
        setFormData({
            firstName: '',
            lastName: '',
            address: '',
            email: '',
            phone: '',
            profileHandle: '',
        });
        setShowModal(false);
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    return (
        <>
            <div className='dance-off-page container mt-5 pt-5'>
                <div className='dance-hero my-5 pt-5 text-center'>
                    <h1>Welcome to the <br />Ultimate Dance-Off!</h1><br /><br />
                    <p>Think you’ve got the moves? <br /> It’s time to show the world your rhythm and compete for <span> $10,000 </span> in cash!</p>
                    <h1 class="hero-dance-text">
                        <img className='img-fluid dance-hero-image' src='/dance/img1.webp' alt='Girl Dancing' />
                        <div className='d-text'>D</div>
                        <div className='a-text'>A</div>
                        <div className='n-text'>N</div>
                        <div className='c-text'>C</div>
                        <div className='e-text'>E</div>
                    </h1>
                </div>
                <div className='prizes text-white'>
                    <div className='prizes-text'>
                        <div className=''>
                            <h1>Prizes</h1>
                            <p>
                                <b>🏆 1st Place: </b><span>$10,000 </span> cash prize
                            </p>
                            <p>
                                <b>🥈 2nd Place: </b><span> $5,000 </span> cash  prize
                            </p>
                            <p>
                                <b>🥉 3rd Place: </b><span> $2,500 </span> cash  prize
                            </p>
                            {/* <p>
                                <b>⭐ Top 10 Winners: </b><span> $1,000 </span> purchase  credit on <a href='https://krizaar.com/' className=' text-white'>Krizaar</a>
                            </p> */}
                        </div>
                    </div>
                    <div className='prizes-img'>
                        <img className='img-fluid' src='/dance/img2.webp' alt='Dancing' />
                    </div>
                </div>
                <h1 className='section-title mt-5 pt-5'>How to Join the Dance-Off</h1>
                <p style={{ fontSize: '1.2rem', color: 'white', textAlign: 'center' }}>
                    Follow these simple steps to enter and show off your best moves:
                </p>
                <div className='prizes-2 text-white'>
                    <div className='prizes-text'>
                        <div className=' pe-3'>
                            <div className='box-div d-flex flex-column gap-3'>
                                <div className='box'><b>1. Download the Shuffle Hub App</b><br />
                                    Head to your app store, search for Shuffle Hub, and download the app to get started!
                                    <div className=" d-flex justify-content-center mt-3">
                                        <div className="get-your-app">
                                            <NavLink to='https://apps.apple.com/us/app/shuffle-by-krizaar/id6736882167'>
                                                <img src="/app-store.png" className="img-fluid" alt="App Store" />
                                            </NavLink>
                                            <NavLink to='https://play.google.com/store/apps/details?id=vr.shuffle.hub'>
                                                <img src="/play-store-button.webp" className="img-fluid" alt="App Store" />
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>

                                <p className='box'><b>2.	Dance to any song of your preference</b><br />
                                    This is your chance to groove to the beats and create magic.</p>
                                <p className='box'><b>3.	Post Your Video on Shuffle</b><br />
                                    Record your dance routine to the selected song and upload it to your Shuffle account with the official hashtag #ShuffleDanceOff.</p>
                                <p className='box'><b>4.	Share with Your Community</b><br />
                                    Spread the word and share your video within your networks. The more likes your video gets, the closer you are to winning!<br /><br />Please make sure to submit your contact information below.</p>
                                <button className='btn-primary mt-3' onClick={handleShowModal}>Submit your contact information</button>
                            </div>
                        </div>
                    </div>
                    <div className='prizes-img'>
                        <img className='img-fluid' src='/dance/img3.webp' alt='Dancing' />
                    </div>
                </div>
                <h1 className='section-title mt-5 pt-5'>Competition Rules</h1>
                <div className='prizes-3 text-white'>
                    <div className='prizes-text ps-5'>
                        <div className='d-flex flex-column gap-3'>
                            <p className='box'>All participants must use the Shuffle Hub app and post their entry.</p>
                            <p className='box'>The competition is open to participants worldwide.</p>
                            <p className='box'>Winners will be determined based on the number of likes their video receives on Shuffle Hub.</p>
                            <p className='box'>Submissions close on March 31st, 2025, at 11:59 PM (UTC).</p>
                            <p className='box'>All decisions are final, and winners will be announced on April 15th, 2025.</p>
                        </div>
                    </div>
                    <div className='prizes-img'>
                        <img className='img-fluid' src='/dance/img4.webp' alt='Dancing' />
                    </div>
                </div>
                <div className='prizes-4 text-white my-5 py-5'>
                    <div className='prizes-text'>
                        <div className=''>
                            <h1>Why Join the Shuffle Dance-Off?</h1>
                            <p>
                                <b>Showcase Your Talent: </b> Share your passion for dance with a global audience.
                            </p>
                            <p>
                                <b>Win Big: </b> Over $20,000 in prizes to be won!
                            </p>
                            <p>
                                <b>Be Part of a Community: </b> Connect with dancers and creators from around the world.
                            </p>
                            <p>
                                <b>Discover Shuffle Hub: </b> Your new go-to platform for creative expression.
                            </p>
                        </div>
                    </div>
                    <div className='prizes-img'>
                        <img className='img-fluid' src='/dance/img5.webp' alt='Dancing' />
                    </div>
                </div>
                <div className='prizes-5 mt-5 pt-5 text-white'>
                    <div className='prizes-text ps-5'>
                        <h1>Ready to Dance?</h1>
                        <div className=''>
                            <p>It’s time to bring your A-game and claim your spot as the ultimate dance champion. </p>
                            <p><span>Download Shuffle Hub</span> now, and let the battle begin!</p>

                        </div>
                    </div>
                    <div className='prizes-img'>
                        <img className='img-fluid' src='/dance/img8.webp' alt='Dancing' />
                    </div>
                </div>
                {showModal && (
                    <div>
                        <div
                            className="modal-backdrop fade show"
                            style={{
                                backgroundColor: 'rgb(0, 0, 0, 1)',
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 1040,
                            }}
                        ></div>

                        <div className='modal h-100 fade show' style={{ display: 'block', zIndex: 1050 }} tabIndex='-1'>
                            <div className='modal-dialog h-100 modal modal-dialog-centered modal-lg'>
                                <div className='modal-content px-5 py-2 bg-black modal-lg'>
                                    {/* <div className='modal-header'>
                                        <h5 className='modal-title text-white'>Share Your Vision</h5>
                                        <button type='button' className='btn-close' style={{ fontSize: '1.5rem', color: 'white' }} onClick={handleCloseModal}><IoClose /></button>
                                    </div> */}
                                    <div className='modal-body'>
                                        <form onSubmit={handleSubmit}>
                                            <div className='row'>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='firstName' className='form-label text-white'>First Name</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='firstName'
                                                        value={formData.firstName}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='lastName' className='form-label text-white'>Last Name</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='lastName'
                                                        value={formData.lastName}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='email' className='form-label text-white'>Email</label>
                                                    <input
                                                        type='email'
                                                        className='form-control'
                                                        id='email'
                                                        value={formData.email}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='phone' className='form-label text-white'>Phone Number</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='phone'
                                                        value={formData.phone}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='mb-3'>
                                                <label htmlFor='address' className='form-label text-white'>Address</label>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    id='address'
                                                    value={formData.address}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className='row'>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='phone' className='form-label'>Profile Handle</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='profileHandle'
                                                        value={formData.profileHandle}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                                <div className='mb-3 col-md-6 col-12'>
                                                    <label htmlFor='phone' className='form-label text-white'>Hashtag</label>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        id='hashtag'
                                                        value={formData.hashtag}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className='my-3 gap-4 d-flex justify-content-center'>
                                                <button className='btn btn-danger' onClick={handleCloseModal}>
                                                    Close
                                                </button>
                                                <button type='submit' className='btn-primary'>
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <h1 className='section-title last-section-title my-5 py-5'>Don’t wait – the stage is yours! #ShuffleDanceOff</h1>
            </div>
        </>
    )
}

export default Dance
