import React, { useEffect } from "react";

const ChildrenSafetyPolicy = () => {
    return (
        <>
            <section className="text-white">
                <div className="container">
                    <div className='terms-of-service'>
                        <h1 className="my-3">Shuffle Children's Privacy Policy</h1>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div style={{ textAlign: 'justify' }}>
                                <p><b>Last Updated:</b>December 18th, 2024</p>
                                <p>Shuffle Inc. ("Shuffle," "we," or "us") is committed to safeguarding children's privacy. This Children's Privacy Policy explains how we collect, use, share, and manage the personal information of users under the age of 13 ("Children") who use the Kids Mode, a separate experience of Shuffle’s services designed for children in the U.S.</p>
                                <p>Kids Mode allows children to enjoy Shuffle's video features while limiting the data collected from them. Children can view videos, create their own content with music and effects, and save them on their device. However, these videos are not stored by Shuffle nor shared with other users. Children have a restricted interactive experience, meaning they cannot exchange messages or have their profiles viewed by others.</p>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Information We Collect From Children</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            When a child registers for Shuffle, we collect minimal information, including their username, password, and birthday. Additionally, we may automatically collect device data like IP addresses, device IDs, browser type, location (at the country level), and information related to app usage, such as videos watched and time spent in the app. If a child contacts us via the feedback form, we collect their email address solely to respond to the inquiry.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>How We Use Children's Information</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            We use this data to operate and maintain our services. For example, usernames and passwords help authenticate users. Automatically collected data is used to provide personalized content, serve contextual ads, perform analytics, enhance security, and comply with legal requirements. Children cannot publicly share personal information or videos, nor can they share profile details.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>How We Share Children's Information</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>
                                            We may share children's information with our corporate group or service providers for internal operations. Personal data may also be disclosed in response to a legal obligation, such as a court order, law enforcement request, or if we believe it necessary to prevent crimes or protect the safety of children. Shuffle does not sell children's information or share it with third parties for cross-context behavioral advertising.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Data Security and Retention</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Shuffle takes reasonable precautions to protect children's information, including encryption. However, online data transmission is not completely secure, and there is some risk involved in transmitting information over the internet. We implement technical measures to ensure the security of our systems and regularly update these measures. Children's data is stored for as long as necessary to fulfill the purpose for which it was collected. Data may be transferred to servers outside the U.S. for processing and storage.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Parental Rights and Choices</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Parents or guardians can request access, correction, or deletion of their child's information, or appeal decisions related to such requests by contacting us through our webform or mailing address. Requests will be verified through a Parental Access Form and additional information as necessary. Shuffle complies with applicable laws and does not discriminate against users based on the exercise of their privacy rights.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Policy Updates</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>This Privacy Policy may be updated from time to time. When changes are made, we will update the "Last Updated" date and post the revised policy or provide other necessary notifications. Parents are encouraged to review the policy periodically.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='checkout__step-header' style={{ cursor: 'auto' }} >
                            <div className=' d-flex gap-4'>
                                <div className='d-flex flex-column justify-content-center align-items-start'>
                                    <div className="checkout__step-name d-flex justify-content-center align-items-center"><h5 className='m-0'>Contact Information</h5></div>
                                    <div className=' d-flex gap-3 mt-1'>
                                        <div style={{ textAlign: 'justify' }}>Parents or guardians with questions or requests regarding this policy can reach us via:
                                            <ul>
                                                <li><b>Website:</b> https://shuffle.studio/childsafety</li>
                                                <li><b>Mailing Address:</b> Shuffle Inc 700 S Rosemary Ave, Ste 204 West Palm Beach, FL 33401 or 1 888 574 9227
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ChildrenSafetyPolicy;
