import React from 'react'
import Header from './Header'
import Home from './Home'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import LoginComponent from './components/LoginComponent';
import SellersClub from './components/SellersClub';
import KrizaarAffluence from './components/KrizaarAffluence';
import KrizaDrops from './components/KrizaDrops';
import AdJunction from './components/AdJunction';
import KrizaarCompliances from './components/KrizaarCompliances';
import Logistics from './components/Logistics';
import KrizaarPay from './components/KrizaarPay';
import Studios from './components/Studios'
import Streaming from './components/Streaming'
import Voting from './components/Voting'
import Moderation from './components/Moderation'
import Monetization from './components/Monetization'
import PrivacyPolicy from './PrivacyPolicy'
import LawEnforcementGuidelines from './LawEnforcementGuidelines'
import IntellectualPropertyPolicy from './IntellectualPropertyPolicy'
import TermsOfService from './TermsOfService'
import VirtualItemsPolicy from './VirtualItemsPolicy'
import Footer from './Footer'
import ChildrenSafetyPolicy from './ChildrenSafetyPolicy'
import DeleteYourAccount from './DeleteYourAccount';
import Dance from './Dance';
import Microdramas from './Microdramas';
import Hub from './Hub';
import ContactUs from './ContactUs';
import TawkChat from './TawkChat';
import Swap from './components/swap_stake/Swap';
import Stake from './components/swap_stake/Stake';
import Affluence from './components/newpages/Affluence';
import SellerClub from './components/newpages/SellerClub';
import ShuffleStudios from './components/newpages/ShuffleStudios';
import KrizaDropship from './components/newpages/KrizaDropship';
// import Swap from './components/Swap';
// import Stake from './components/Stake';

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<LoginComponent />} />
          {/* <Route path="/explore" element={<Explore />} /> */}
          {/* <Route path="/bytes" element={<Bytes />} /> */}
          {/* <Route path="/add-music" element={<AddMusicForm />} /> */}
          {/* <Route path="/video-details" element={<ContentDetails />} /> */}
          {/* start others links */}
          <Route path="/sellersclub" element={<SellerClub />} />
          {/* <Route path="/monetization" element={<KrizaarAffluence />} /> */}
          <Route path="/monetization" element={<Affluence />} />
          <Route path="/shuffledrops" element={<KrizaDrops />} />
          <Route path="/krizadropship" element={<KrizaDropship />} />
          <Route path="/adjunction" element={<AdJunction />} />
          <Route path="/compliance" element={<KrizaarCompliances />} />
          <Route path="/logistics" element={<Logistics />} />
          <Route path="/pay" element={<KrizaarPay />} />
          <Route path="/studios" element={<ShuffleStudios />} />
          <Route path="/streaming" element={<Streaming />} />
          <Route path="/voting" element={<Voting />} />
          <Route path="/moderation" element={<Moderation />} />
          {/* <Route path="/monetization" element={<Monetization />} /> */}
          <Route path="/swap" element={<Swap />} />
          <Route path="/stake" element={<Stake />} />
          {/* end others links */}

          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/lawenforcementguidelines" element={<LawEnforcementGuidelines />} />
          <Route path="/intellectualpropertypolicy" element={<IntellectualPropertyPolicy />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/virtualitempolicy" element={<VirtualItemsPolicy />} />
          <Route path="/childsafety" element={<ChildrenSafetyPolicy />} />
          <Route path="/deleteaccount" element={<DeleteYourAccount />} />
          <Route path="/danceoff" element={<Dance />} />
          <Route path="/microdramas" element={<Microdramas />} />
          <Route path="/hub" element={<Hub />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/contact" element={<ContactUs />} />
        </Routes>
        <TawkChat />
        <Footer />
      </Router>
    </>
  )
}

export default App