import React from 'react'
import './newstyles.css'
import image from './pic4.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Autoplay } from 'swiper/modules';
import { useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Affluence = () => {
    return (
        <>
            <div className='affluence-section'>
                <div className='affluence-section-hero'>
                    <div className='affluence-section-hero1'>
                        <h1>Welcome to <span>Affluence</span></h1>
                        <p>Empowering Affiliates and Influencers in the Digital Age</p>
                    </div>
                </div>
                <div className='container'>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Problem and Solution</h1>
                        </div>
                        <div className='affluence-problem-section'>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-problem-section-text'>
                                <h4>The Problem</h4>
                                <h2>The Trust Gap in the Marketplace</h2>
                                <p>Have you ever lost earnings due to failed transactions or unfulfilled promises? For affiliates and influencers, distrust, lack of transparency, and inconsistent payouts have created significant hurdles in the creator economy. The fear of losing valuable revenue or not being paid on time is a pain shared by many in the industry.</p>
                            </div>
                        </div>
                        <div className='affluence-problem-section mt-5'>
                            <div className='affluence-problem-section-text'>
                                <h4>The Solution</h4>
                                <h2>Affluence - Built on Blockchain, Driven by Trust</h2>
                                <p>Affluence, powered by Shuffle Hub’s innovative ecosystem, is here to redefine how affiliates and influencers thrive. By integrating blockchain technology, we ensure trust, transparency, and instant payouts—eliminating the need to second-guess your earnings or partnerships.</p>
                            </div>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Why Affluence ?</h1>
                        </div>
                        <div className='why-affluences-boxes'>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Transparency Through Blockchain</h2>
                                    <p>Every transaction is secured, immutable, and visible on our blockchain-powered platform. Know exactly where your commissions are coming from and when you’ll get paid.</p>
                                </div>
                            </div>
                            <div className='box2'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Instant Payouts</h2>
                                    <p>With our decentralized architecture, delays are a thing of the past. Affiliates and influencers enjoy real-time payments directly to their accounts.</p>
                                </div>
                            </div>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Empowered Monetization</h2>
                                    <p>Track analytics, measure engagement, and optimize your strategy with our cutting-edge tools. Influencers can easily see how their content drives earnings, while affiliates can manage multiple campaigns seamlessly.</p>
                                </div>
                            </div>
                            <div className='box2'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>Simplified Affiliate Links</h2>
                                    <p>Generate, share, and promote product links effortlessly. Affluence’s intuitive interface lets you focus on what matters—creating and earning.</p>
                                </div>
                            </div>
                            <div className='box1'>
                                <div className='box-image'>
                                    <img src={image} alt='Image' className='img-fluid' />
                                </div>
                                <div className='box-content'>
                                    <h2>A Unified Ecosystem</h2>
                                    <p>Seamlessly integrated with Shuffle Hub’s features like video sharing, live streaming, and e-commerce, Affluence enables content creators to connect with audiences and brands like never before.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>How It Works ?</h1>
                        </div>
                        <div className="affluence-how-it-works2">
                            <div className='affluence-how-it-works-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-how-it-works-text'>
                                <div className='affluence-box'>
                                    <h3>1. Sign Up & Set Up</h3>
                                    <p>Create your profile, link your accounts, and get started within minutes.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>2. Generate & Share Links</h3>
                                    <p>Use our platform to create affiliate links and share them with your network or embed them in your content.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>3. Track Performance</h3>
                                    <p>Monitor clicks, conversions, and commissions with real-time data analytics.</p>
                                </div>
                                <div className='affluence-box'>
                                    <h3>4. Earn & Withdraw Instantly</h3>
                                    <p>Enjoy immediate access to your earnings, backed by our blockchain-secured system.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Your Pain, Our Priority</h1>
                        </div>
                        <div className='join-community'>
                            <div className="join-community-box">
                                <h3>For Affiliates</h3>
                                <p>Maximize your earning potential by promoting products and receiving guaranteed commissions.</p>
                            </div>
                            <div className="join-community-box">
                                <h3>For Influencers</h3>
                                <p>Monetize your content effectively by engaging with brand sponsors and tracking viewership impact.</p>
                            </div>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5 d-flex flex-column'>
                            <h1>Join a Community That Values Your Effort</h1>
                            <p>At Affluence, we’re not just another platform. We’re a solution designed with your challenges in mind:</p>
                        </div>
                        <div className="affluence-how-it-works">
                            <Swiper
                                // slidesPerView={3}
                                spaceBetween={0}
                                centeredSlides={true}
                                loop={true}
                                speed={1500}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    992: {
                                        slidesPerView: 3,
                                    }
                                }}
                                autoplay={true}
                                modules={[Autoplay, Navigation]}
                            >
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>No More Uncertainty</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Blockchain guarantees transparent records of all transactions.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>No Delayed Payments</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Get paid instantly, every time.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>No Guesswork</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Comprehensive analytics empower you to make informed decisions.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>No More Uncertainty</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Blockchain guarantees transparent records of all transactions.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>No Delayed Payments</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Get paid instantly, every time.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="affluence-how-it-works-card">
                                        <h5>No Guesswork</h5>
                                        <div className='line'></div>
                                        <p className="m-0">Comprehensive analytics empower you to make informed decisions.</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                    <div className='affluence-problem-solution-section my-5 pt-5'>
                        <div className='section-header mb-5'>
                            <h1>Be Part of the Revolution</h1>
                        </div>
                        <div className='affluence-problem-section'>
                            <div className='affluence-problem-section-image'>
                                <img src={image} alt='Affluence Image' className='img-fluid' />
                            </div>
                            <div className='affluence-problem-section-text d-flex justify-content-around'>
                                <div className='affluence-box'>

                                    <p>Thousands of affiliates and influencers are transforming their careers with Affluence. Don’t let outdated systems hold you back. Join a platform that puts trust and transparency first—so you can focus on what you do best.</p>
                                </div>
                                <div>
                                    <h2 className='mb-3'>Get Started Now</h2>
                                    <div className='affluence-box'>
                                        <p>Sign Up for Free and unlock your earning potential today.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Affluence
